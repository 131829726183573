
@tailwind base;
@tailwind components;
@tailwind utilities;

/* TODO: could optimize font loading more for less FOUT & FOIT */
/* MEMO: inspired by https://css-tricks.com/snippets/css/using-font-face/ */
@font-face {
  font-family: 'ABCWhyte';
  src:  url('assets/fonts/ABCWhyte-Medium.woff2') format('woff2'),
        url('assets/fonts/ABCWhyte-Medium.woff') format('woff'),
        url('assets/fonts/ABCWhyte-Medium.otf') format('otf');
}
@font-face {
  font-family: 'NimbusSanL';
  src:  url('assets/fonts/NimbusSanL-Regular.woff') format('woff'),
        url('assets/fonts/NimbusSanL-Regular.otf') format('otf');
}
